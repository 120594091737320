exports.shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.hash || location.pathname.includes('article')) {
    const currentPosition = getSavedScrollPosition(location);
    setTimeout(() => {
      sessionStorage.setItem(
        `@@scroll|${location.pathname}|initial`,
        currentPosition[1],
      );
    }, 0);
  }
  if (location.pathname === '/pricing') {
    return !location.search;
  }
};

exports.onInitialClientRender = () => {
  const chatContainer = document.createElement('div');
  chatContainer.id = 'chatbotContainer';
  document.body.appendChild(chatContainer);

  const link = document.createElement('link');
  link.href = 'https://app.algobulls.com/chatbot.css';
  link.rel = 'stylesheet';
  document.head.appendChild(link);
};
